<template>
  <b-modal
    id="pause-modal"
    hide-header
    hide-footer
    no-stacking
    centered
    size="lg"
    @show="onShow"
  >
    <div class="header">
      <div class="header-content">
        <p>Finalizar depois</p>
        <Close @click="closeModal" />
      </div>
    </div>
    <hr>
    <div class="bottom">
      <div class="body">
        <div class="radio-container">
          <p>Selecione uma situação para essa consulta</p>

          <div class="radio-section">
            <div class="radio">
              <input
                type="radio"
                name="attendance_status"
                id="patient_dilating"
                value="Paciente dilatando"
                @input="getRadioValue"
              >
              <label for="patient_dilating">Paciente dilatando</label>
            </div>

            <div class="radio">
              <input
                type="radio"
                name="attendance_status"
                id="patient_examination"
                value="Paciente em exames"
                @input="getRadioValue"
              >
              <label for="patient_examination">Paciente em exames</label>
            </div>

            <div class="radio">
              <input
                type="radio"
                name="attendance_status"
                id="pre-finished_attendance"
                value="Finalização pendente"
                @input="getRadioValue"
              >
              <label for="pre-finished_attendance">Liberar paciente e finalizar consulta depois</label>
            </div>

            <div class="radio">
              <input
                checked
                type="radio"
                name="attendance_status"
                id="stand-by_attendance"
                value="Consulta em andamento"
                @input="getRadioValue"
              >
              <label for="stand-by_attendance">Sair e manter consulta em andamento</label>
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <button class="btn cancel-button" @click="cancelAttendance">
          Cancelar consulta
        </button>

        <button class="btn confirm-button" @click="pauseAttendance">
          Confirmar
        </button>
      </div>
    </div>

  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Close from '@/assets/icons/close.svg'
import { essilorPatientOut } from '@/utils/essilor/essilorIntegrationHelper'

export default {
  components: {
    Close
  },
  computed: {
    ...mapState({
      attendance: state => state.attendance.attendance,
    })
  },
  data() {
    return {
      pauseReason: null,
    }
  },
  props: {
    attendanceRedirect: String
  },
  methods: {
    ...mapActions('attendance', ['closeAttendance', 'clearAttendance']),
    onShow() {
      this.pauseReason = 'Consulta em andamento'
    },
    closeModal() {
      this.$bvModal.hide('pause-modal')
    },
    getRadioValue(val) {
      this.pauseReason = val.target.value;
    },
    async pauseAttendance() {
       try {
        await this.api.pauseAttendance(this.attendance.clinic.id, this.attendance.id, this.pauseReason)
        this.$toast.success('Consulta pausada com sucesso');
        this.closeAttendance()
        this.attendanceRedirect ?
          this.$router.push(this.attendanceRedirect) :
          this.$router.push('/visao-geral');
        this.clearAttendance()
      } catch(err) {
        this.$toast.error(err.message)
      }
    },
    async cancelAttendance() {
      await essilorPatientOut(this.attendance);
      const isLoading = this.$loading.show()
      try {
        await this.api.updateAttendance(this.attendance.clinic.id, this.attendance.patient.id, { action: 'end_attendance', canceled: true })
        this.closeAttendance()
        this.$router.push(`/pacientes/${this.attendance.patient.id}`)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
  }
}
</script>
<style lang="scss">
#pause-modal {
  font-family: 'Nunito Sans';
  font-style: normal;

  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 800px;

    .modal-body {
      padding: 0;

      .header {
        .header-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px;

          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #525C7A;
          }

          svg {
            height: 24px;
            width: 24px;
            fill: #7F91D2;
            cursor: pointer;
          }
        }
      }

      hr {
        margin: 0;
      }

      .bottom {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 24px;

        .body {
          .radio-container {
            display: flex;
            flex-direction: column;
            gap: 24px;

            p {
              font-family: 'Red Hat Display';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 134%;
              letter-spacing: -0.01em;
              color: #0C1D59;
            }

            .radio-section {
              display: flex;
              flex-direction: column;
              gap: 16px;

              .radio {
                display: flex;
                align-items: center;
                gap: 12px;

                label {
                  margin: 0;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 150%;
                  color: #525C7A;
                  cursor: pointer;
                }

                input {
                  height: 24px;
                  width: 24px;
                  cursor: pointer;
                }
              }
            }
          }

          hr {
            margin: 24px 0;
          }
        }

        .footer {
          display: flex;
          justify-content: space-between;

          .cancel-button {
            padding: 14px 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #F63220;
          }

          .confirm-button {
            padding: 14px 16px;
            background: #305BF2;
            border-radius: 8px;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}
</style>
